<template>
<div id="TaskListTip">    
    <div class="modal fade " id="taskListTip" tabindex="-1" role="dialog" aria-labelledby="notice7Label" aria-hidden="true">
    <div class="modal  modal-dialog modal-dialog-centered">
        <div class="modal-content nicole-modal">
            <div class="modal-header">
                <h4 class="text-center text-lg fw-bold text-dark mb-0">任務完成指標</h4>
            </div>
            <div class="modal-body">    
                <div class="d-flex mb-4">
                    <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
                    <h5 class=" text-smm text-dark mb-0">文章朗讀：將朗讀音檔從頭到尾聽完</h5>
                </div>    
                <div class="d-flex mb-4">
                    <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
                    <h5 class=" text-smm text-dark mb-0">單字預習：全部的單字音檔播過一次</h5>
                </div>    
                <div class="d-flex mb-4">
                    <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
                    <h5 class=" text-smm text-dark mb-0">老師講解：將老師講解音檔從頭到尾聽完</h5>
                </div>    
                <div class="d-flex mb-4">
                    <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
                    <h5 class=" text-smm text-dark mb-0">單字複習：認識全部的單字</h5>
                </div>    
                <div class="d-flex">
                    <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
                    <h5 class=" text-smm text-dark mb-0">隨堂測驗：作答全部的題目，完成交卷</h5>
                </div>
            </div>
            <div class="modal-footer ">
                <h5 class="text-success text-simbold mb-0 text-mm w-50 text-center py-1" @click="onFunc">我知道了</h5>
            </div>   
        </div>
    </div>
    </div>  
</div>
</template>


<script>
export default {
    name: 'TaskListTip',   
        props: {
            onfunc: Function,
        }, 
    methods:{
        onShow(){
            $('#taskListTip').modal("show");
        },
        onHide(){
            $('#taskListTip').modal("hide");
        },
        onFunc(){
            if(this.onfunc != null && this.onfunc != undefined){
                $(`#taskListTip`).on('hidden.bs.modal', () => {
                    $(`#taskListTip`).off('hidden.bs.modal');
                    this.onfunc();
                });
            }
            $('#taskListTip').modal("hide");
        },
    },
    created(){  
    },
    mounted(){   
    },
    beforeDestroy(){
        //$('#taskListTip').remove();
    }
}
</script>
