<template>
    <div id="TaskList" class="h-100">
    <TaskListTip ref="taskListTip" :onfunc="onTipConfirm"/>
    <TaskListExam ref="taskListExam" :onfuncleft="onRestWrongExam"  :onfuncright="onRestExam" :isdisabledleft="isDisabledLeft" :isdisabledright="isDisabledRight"/>    
    <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
    <Header ref="Header" :reloadfunc="onReload"/>  
    <body class="">
    <!-- header -->
        <nav class="navbar fixed-top nicole-noshadow">
        <div class="container">
            <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
            <img class="avatar avatar-sm" src="assets/img/icon-back-white.png">
            </a>            
            <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
            </a>             
            <a class="navbar-brand font-weight-bolder me-0 text-right col-4" v-show="$GlobalData.uid != ''" @click="onTip">
            <img class="avatar avatar-xs me-1" src="assets/img/img035.png">   
            </a> 
        </div>
        </nav>      
    <!-- End header -->

    <div class="">
        <div class="position-relative ">
            <div class="w-100 listen-img-nicole border-0" v-real-task-list-background-img="lesson.image" style="background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url(assets/img/noback.png); background-position: center;background-size: 100%, 100%;background-repeat: no-repeat; background-size: cover;" ></div>
            <div class="cardname-botton2 ">
            <!-- eslint-disable vue/no-v-html -->
            <h5 class="text-g text-white text-bolder text-card-name  mb-0 me-3 me-md-5 hide2 lh18" v-html="lesson.name"></h5>
            <!--eslint-enable-->
                <div class="mt-2 mt-md-3">
                <span class="badge badge-secondary text-dark text-card-small border-0 rounded-pill px-2 py-1 me-1">{{lesson.english_level_name}}</span>
                <span class="badge badge-secondary text-dark text-card-small border-0 rounded-pill px-2 py-1 me-1" v-for="(lessonMode,lessonModeIndex) of lesson_mode" :key="lessonModeIndex">{{lessonMode.name}}</span>
                </div>
            </div> 
            <div class="">
            <div class="listentest2 card w-100 nicole-noshadow">
            <div class="container mt-2 mt-md-4" >  
            <table class="table table-l table-borderless align-items-center mb-0">
            <tbody>
                <tr @click="onEssay">
                <td>  
                    <div class="d-flex align-items-center">
                    <img :src="(isShowQuestStatus && hasHistoryEssay)? 'assets/img/ic_essay_finished.png':'assets/img/ic_essay_notyet.png'" class="avatar avatar-s rounded-0  me-md-2">
                    <h5 class="text-wrap text-card-title2   text-bold ms-2 mb-0">1.  文章朗讀</h5>
                    <img src="assets/img/ic_like.png" class="avatar avatar-xs rounded-0 ms-2" v-if="(isShowQuestStatus && hasHistoryEssay)">   
                    </div>        
                </td>
                <td class="align-middle text-right text-card-small">
                    <h5 class=" text-wrap text-card-title text-success ms-2 mb-0 text-simbold" v-if="hasHistoryEssay"> 已完成 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                    <h5 class=" text-wrap text-card-title text-light2 ms-2 mb-0 text-simbold" v-if="!hasHistoryEssay"> 未開始 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                </td>
                </tr>  
                <tr @click="onWordPreview">
                <td>  
                    <div class="d-flex align-items-center">
                    <img :src="(isShowQuestStatus && hasHistoryWordPreview)? 'assets/img/ic_essay_finished.png':'assets/img/ic_essay_notyet.png'" class="avatar avatar-s rounded-0  me-md-2">
                    <h5 class="text-wrap text-card-title2   text-bold ms-2 mb-0">2.  單字預習</h5> 
                    <img src="assets/img/ic_like.png" class="avatar avatar-xs rounded-0 ms-2" v-if="(isShowQuestStatus && hasHistoryWordPreview)">   
                    </div>        
                </td>
                <td class="align-middle text-right text-card-small">
                    <h5 class=" text-wrap text-card-title text-success ms-2 mb-0 text-simbold" v-if="hasHistoryWordPreview"> 已完成 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                    <h5 class=" text-wrap text-card-title text-light2 ms-2 mb-0 text-simbold" v-if="!hasHistoryWordPreview"> 未開始 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                </td>
                </tr>              
                <tr @click="onExplanation">
                <td>  
                    <div class="d-flex align-items-center">
                    <img :src="(isShowQuestStatus && hasHistoryExplanation)? 'assets/img/ic_essay_finished.png':'assets/img/ic_essay_notyet.png'" class="avatar avatar-s rounded-0  me-md-2">
                    <h5 class="text-wrap text-card-title2   text-bold ms-2 mb-0">3.  老師講解  </h5> 
                    <img src="assets/img/ic_like.png" class="avatar avatar-xs rounded-0 ms-2" v-if="(isShowQuestStatus && hasHistoryExplanation)"> 
                    </div>        
                </td>
                <td class="align-middle text-right text-card-small">
                    <h5 class=" text-wrap text-card-title text-success ms-2 mb-0 text-simbold" v-if="hasHistoryExplanation"> 已完成 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                    <h5 class=" text-wrap text-card-title text-light2 ms-2 mb-0 text-simbold" v-if="!hasHistoryExplanation"> 未開始 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                </td>
                </tr>              
                <tr @click="onWordReview">
                <td>  
                    <div class="d-flex align-items-center">
                    <img :src="(isShowQuestStatus && hasHistoryWordReview)? 'assets/img/ic_essay_finished.png':'assets/img/ic_essay_notyet.png'" class="avatar avatar-s rounded-0  me-md-2">
                    <h5 class="text-wrap text-card-title2   text-bold ms-2 mb-0">4.  單字複習 </h5> 
                    <img src="assets/img/ic_like.png" class="avatar avatar-xs rounded-0 ms-2" v-if="(isShowQuestStatus && hasHistoryWordReview)"> 
                    </div>        
                </td>
                <td class="align-middle text-right text-card-small">
                    <h5 class=" text-wrap text-card-title text-success ms-2 mb-0 text-simbold" v-if="hasHistoryWordReview"> 已完成 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                    <h5 class=" text-wrap text-card-title text-light2 ms-2 mb-0 text-simbold" v-if="!hasHistoryWordReview"> 未開始 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                </td>
                </tr>              
                <tr @click="onExam">
                <td>  
                    <div class="d-flex align-items-center">
                    <img :src="(isShowQuestStatus && hasHistoryExam)? 'assets/img/ic_essay_finished.png':'assets/img/ic_essay_notyet.png'" class="avatar avatar-s rounded-0  me-md-2">
                    <h5 class="text-wrap text-card-title2   text-bold ms-2 mb-0" >5.  隨堂測驗 </h5> 
                    <img src="assets/img/ic_like.png" class="avatar avatar-xs rounded-0 ms-2" v-if="(isShowQuestStatus && hasHistoryExam)">   
                    </div>        
                </td>
                <td class="align-middle text-right text-card-small">
                    <h5 class=" text-wrap text-card-title text-success ms-2 mb-0 text-simbold" v-if="correctRate > -1 && hasHistoryExam"> 答對率 {{correctRate}}% <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                    <h5 class=" text-wrap text-card-title text-success ms-2 mb-0 text-simbold" v-if="correctRate == -1 && hasHistoryExam"> 已完成 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                    <h5 class=" text-wrap text-card-title text-light2 ms-2 mb-0 text-simbold" v-if="!hasHistoryExam"> 未開始 <i class="fas fa-chevron-right text-muted ms-1"></i></h5> 
                </td>
                </tr>            
            </tbody>
            </table>
                </div>
                </div>

    <div class="nicolespace"></div>
    <div class="nicolespace"></div>
            </div>
        </div>
    </div>     
    </body>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import TaskListTip from '@/components/TaskListTip.vue'
import TaskListExam from '@/components/TaskListExam.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 


export default {
    name: 'TaskList',
    components: {
        Header,
        TaskListTip,
        TaskListExam,
        CommonAlert2Func,
    },
    data () {
        return { 
            // api setting
            setDebugMode:false ,
            apiDebug: false,
            apiName: "page_task_list",
            act: "",
            form: {},

            // data
            lesson: {},
            lesson_mode: [],
            history_user_product: [],
            history_user_lesson_schedule: [],
            history_user_lesson_schedule_count: 0,
            isShowQuestStatus: false,
            isVisitor: true,
            isMember: false,
            hasHistoryEssay: false,
            hasHistoryWordPreview: false,
            hasHistoryExplanation: false,
            hasHistoryWordReview: false,
            hasHistoryExam: false,
            correctRate: -1,

            //commonAlert2Func
            title: "",
            content: "",
            btnNameUp: "",
            btnNameDown: "",
            onFuncUp: Function,
            onFuncDown: Function,
            backUrl:"",
            backPath:"",
            isDisabledLeft: true,
            isDisabledRight: false,
            isQuiz: false,
            quiz: "",
            lsid: "",
            isLoading: false,


            /* ZEN-1874
             * 1.首頁 > 大家都在學 > 觀看全部 > 任一課程 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到觀看全部頁  everybody_learn
             * 2.首頁 > 按主題學 > 任一課程 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到按主題學頁  topic_learn
             * 3.首頁 > 按等級學 > 任一等級(ex.高級))的任一課程 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到按等級學高級頁籤中  level_learn + ?elid=level3
             * 4.每日學習 > 任一排課 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到每日學習頁 daily_learn
             * 5.我的課程 > 自訂課程 > 任一期的任一排課 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到自訂課程該期的頁籤中  /my_courses_custom
             * 6.我的課程 > 深度學習 > 任一學習包的任一課程 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到深度學習該包的課程清單中  /my_courses_detail + ?ptid=2
             * 7.學習紀錄 > 任一課程 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到學習紀錄的課程清單中  /learning_record
             * 8.首頁 > 任一課程 > 任一任務(ex.文章朗讀)，點選兩次返回，應回到首頁
             * 備註:如果只進入任務清單而不點入任一任務時，直接返回是可以回到原本進來的頁面的
             **/
             // taskBack: "EverybodyLearn",view : "all"
             // taskBack: "EverybodyLearn",view : "topic"
             // taskBack: "EverybodyLearn",view : "level"
             // taskBack: "DailyLearn",
             // taskBack: "MyCoursesCustom",
             // taskBack: "MyCoursesDetail",ptid: ""
             // taskBack: "LearningRecord",

             taskBack: "Home",
        }
    },
    methods: {
        onReload(name, query){
            this.onStartup(query);
        },
        openWebLoginViewCallback(){},
        openLogin(){
            this.$JsBridge.callHandler('openWebLoginView',JSON.stringify({backUrl:encodeURIComponent(btoa(this.backUrl)), type:"",skipBack:`TaskList?lsid=${this.lsid}`}), this.openWebLoginViewCallback);
            this.backUrl = "";
        },
        openVueLoginViewCallback(){},
        openVueLogin(){
            this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:this.backUrl,skipBack:`TaskList?lsid=${this.lsid}`}), this.openVueLoginViewCallback);
            this.backUrl = "";
        },
        openLoginCallback(data){

        },
        openVip(){
            if(this.lsid != ""){
                console.log("xxx 開啟升級VIP會員");
                this.$router.push({
                    name: "UpgradeVip",
                    params : {
                        lsid : this.lsid,
                        backUrl: "TaskList",
                        taskBack: this.taskBack
                    }
                }); 
            }
        },
        openLoginAlert(funcUp){
            this.title = '';
            this.content = '想要體驗更多內容，立即註冊會員免費體驗三日';
            this.btnNameUp = '馬上註冊';
            this.btnNameDown = '下次再說';
            this.onFuncUp = funcUp; 
            this.onFuncDown = null;         
            this.$refs.commonAlert2Func.onShow();
        },
        openVipAlert(funcUp){
            this.title = '';
            this.content = '想要體驗更多內容，立即升級VIP會員';
            this.btnNameUp = '立即升級';
            this.btnNameDown = '下次再說';
            this.onFuncUp = funcUp; 
            this.onFuncDown = null;         
            this.$refs.commonAlert2Func.onShow();
        },
        onTipConfirm(){

        },
        onRestExam(){
            if(this.lsid != ""){
                this.backUrl = `TaskList?lsid=${this.lsid}`;
                if(config && config.old_exam && config.old_exam=="Y") {
                    var beforeBack = "";
                    if(this.backUrl != ""){
                        beforeBack = this.backUrl;
                    }else if(this.backPath != ""){
                        beforeBack = this.backPath;
                    }
                    var vueBack = window.btoa(`TaskList?lsid=${this.lsid}&backUrl=${beforeBack}`);
                    this.$JsBridge.callHandler('openWebView', `/quiz?lsid=${this.lsid}&tryError=N&vue=${vueBack}`, this.changeToWebCallback);
                }else{
                    var back = ""
                    if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl != undefined){
                        back = this.$route.params.backUrl;
                    }
                    console.log(`back ==> ${back}`);
                    this.$router.push({
                        name: "Quiz",
                        params : {
                            lsid : this.lsid,
                            backMode : "vue",
                            backUrl: `TaskList?lsid=${this.lsid}&backUrl=${back}`,
                            tryError: "N",
                        }
                    });
                }
            }
        },
        onRestWrongExam(){
            if(this.lsid != ""){
                this.backUrl = `TaskList?lsid=${this.lsid}`;
                if(config && config.old_exam && config.old_exam=="Y") {
                    var beforeBack = "";
                    if(this.backUrl != ""){
                        beforeBack = this.backUrl;
                    }else if(this.backPath != ""){
                        beforeBack = this.backPath;
                    }
                    var vueBack = window.btoa(`TaskList?lsid=${this.lsid}&backUrl=${beforeBack}`);
                    this.$JsBridge.callHandler('openWebView', `/quiz?lsid=${this.lsid}&tryError=N&vue=${vueBack}`, this.changeToWebCallback);
                }else{
                    var back = ""
                    if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl != undefined){
                        back = this.$route.params.backUrl;
                    }
                    console.log(`back ==> ${back}`);
                    this.$router.push({
                        name: "Quiz",
                        params : {
                            lsid : this.lsid,
                            backMode : "vue",
                            backUrl: `TaskList?lsid=${this.lsid}&backUrl=${back}`,
                            tryError: "Y",
                        }
                    });
                }
            }
        },
        changeToWebCallback(){

        },
        onEssay(){
            if(this.lsid != ""){
                // 毎日學習要跳轉回去 
                var beforeBack = "";
                if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl !== "undefined"){
                    beforeBack = this.$route.params.backUrl;
                }
                if(config && config.old_essay_read && config.old_essay_read=="Y") {
                    var vueBack = window.btoa(`TaskList?lsid=${this.lsid}&backUrl=${beforeBack}`);
                    this.$JsBridge.callHandler('openWebView', `/essay_read?lsid=${this.lsid}&vue=${vueBack}`, this.changeToWebCallback);
                }else{
                    this.$refs.Header.isLogOut(this.isLogOutEssayReadCallback);
                }
            }
        },
        isLogOutEssayReadCallback(status){
            if(status){
                this.$router.push(
                {
                    name : 'Home',
                    params:{
                        isLogOut: 'Y',
                    }
                });
            }else{
                var beforeBack = "";
                if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl !== "undefined"){
                    beforeBack = btoa(this.$route.params.backUrl);
                }
                this.$JsBridge.callHandler('openEssayRead',JSON.stringify({
                    lsid: this.lsid,
                    backUrl: `TaskList?lsid=${this.lsid}&backUrl=${beforeBack}`,
                    mode: "vue",
                    type: "open",
                }), this.openEssayReadCallback);
            }
        },
        openEssayReadCallback(){},
        onWordPreview(){
            if(this.lsid != ""){
                var back = ""
                if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl != undefined){
                    back = this.$route.params.backUrl;
                }

                this.$router.push({
                    name: "WordPreview",
                    params : {
                        lsid : this.lsid,
                        backMode : "vue",
                        backUrl: `TaskList?lsid=${this.lsid}&backUrl=${back}`,
                    }
                });
            }
        },
        isLogOutTeacherExplainCallback(status){
            if(status){
                this.$router.push(
                {
                    name : 'Home',
                    params:{
                        isLogOut: 'Y',
                    }
                });
            }else{
                var beforeBack = "";
                if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl !== "undefined"){
                    beforeBack = btoa(this.$route.params.backUrl);
                }
                this.$JsBridge.callHandler('openTeacherExplain',JSON.stringify({
                    lsid: this.lsid,
                    backUrl: `TaskList?lsid=${this.lsid}&backUrl=${beforeBack}`,
                    mode: "vue",
                    type: "open",
                }), this.openTeacherExplainCallback);
            }
        },
        onExplanation(){
            if(this.lsid != ""){
                if(this.$JsBridge.isiOS() || this.$JsBridge.isAndroid()){
                    this.$refs.Header.isLogOut(this.isLogOutTeacherExplainCallback);
                }else{
                    var vueBack = window.btoa(`TaskList?lsid=${this.lsid}`);
                    this.$JsBridge.callHandler('openWebView', `/teacher_explain?lsid=${this.lsid}&vue=${vueBack}`, this.changeToWebCallback);
                }
            }
        },
        openTeacherExplainCallback(){},
        onWordReview(){
            if(this.lsid != ""){
                this.backUrl = `TaskList?lsid=${this.lsid}`;
                if(this.isVisitor){
                    this.openLoginAlert(this.openVueLogin);
                }else if(this.isMember){
                    this.openVipAlert(this.openVip);
                }else{
                    if(config && config.old_exam && config.old_exam=="Y") {
                        // 毎日學習要跳轉回去 
                        var beforeBack = "";
                        if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl !== "undefined"){
                            beforeBack = this.$route.params.backUrl;
                        }
                        this.$JsBridge.callHandler('openWebView', `/word_reexercise?lsid=${this.lsid}&vue=TaskList?lsid=${this.lsid}&backUrl=${beforeBack}`, this.changeToWebCallback);
                    }else{
                        var back = ""
                        if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl != undefined){
                            back = this.$route.params.backUrl;
                        }
                        console.log(`back ==> ${back}`);
                        this.$router.push({
                            name: "WordReexercise",
                            params : {
                                lsid : this.lsid,
                                backMode : "vue",
                                backUrl: `TaskList?lsid=${this.lsid}&backUrl=${back}`,
                            }
                        });
                    }
                }
            }
        },
        onExam(){
            if(this.lsid != ""){
                if(this.isVisitor){
                    this.openLoginAlert(this.openVueLogin);
                }else if(this.isMember){
                    this.openVipAlert(this.openVip);
                }else{
                    this.$refs.taskListExam.onShow();
                }
            }
        },
        onTip(){
          this.$refs.taskListTip.onShow();
        },
        setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
        onBack(){
            this.$refs.taskListTip.onHide();
            this.$refs.taskListExam.onHide();

            this.$GlobalData.lastLsid = "";
            
            if(this.backPath == ""){                
                if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl !== "undefined"){
                    var name = "";
                    var params = {};
                    if(this.backUrl.indexOf("?") > -1){
                        name = this.backUrl.substring(0, this.backUrl.indexOf("?"));
                        var queryStr = this.backUrl.substring(this.backUrl.indexOf("?") + 1, this.backUrl.length);
                        if(queryStr != ""){
                        const urlSearchParams = new URLSearchParams(queryStr);   
                            params = Object.fromEntries(urlSearchParams.entries()); 
                        }
                    }
                    if(name != ""){
                        this.$router.push({
                            name: name,
                            backUrl: "TaskList",
                            params : params
                        });
                    }else{
                        this.$router.push({
                            name: this.$route.params.backUrl,
                            backUrl: "TaskList",
                            params : {
                                view : this.$route.params.view,
                                ptid : this.$route.params.ptid
                            }
                        });
                    }
                }
                else if(this.$route.query.backUrl && this.$route.query.backUrl != "" && this.$route.query.backUrl !== "undefined"){
                    this.$router.push({
                        name: this.$route.query.backUrl
                    });
                }
                else{
                    this.$router.push({
                        name: "Home"
                    });
                }
            }else{
                if(this.backPath == "DailyLearn"){
                    this.$router.push({
                        name: "DailyLearn"
                    });
                }else if(/EverybodyLearn/.test(this.backPath)){
                    var name = "";
                    var params = {};
                    if(this.backPath.indexOf("?") > -1){
                        name = this.backPath.substring(0, this.backPath.indexOf("?"));
                        var queryStr = this.backPath.substring(this.backPath.indexOf("?") + 1, this.backPath.length);
                        if(queryStr != ""){
                        const urlSearchParams = new URLSearchParams(queryStr);   
                            params = Object.fromEntries(urlSearchParams.entries()); 
                        }
                    }
                    if(name != ""){
                        this.$router.push({
                            name: name,
                            params : params
                        });
                    }
                }else if(/everybody_learn/.test(this.backPath) || /topic_learn/.test(this.backPath) || /level_learn/.test(this.backPath) || /daily_learn/.test(this.backPath) || /my_courses_custom/.test(this.backPath) || /my_courses_detail/.test(this.backPath) || /learning_record/.test(this.backPath)){
                    this.$JsBridge.callHandler('openWebView', this.backPath, this.changeToWebCallback);
                }else{
                    this.$JsBridge.callHandler('openWebView', '/home', this.changeToWebCallback);
                }
            }
        },
        getTaskList(lsid){
            this.apiName = 'page_task_list';
            oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid, lsid:lsid}).then(
            res => 
                {
                    if(res != null && res.lesson != null && res.lesson.length > 0){   
                        this.lesson = res.lesson[0];
                        this.lsid = this.lesson.lsid;
						
									
						if(config && config.old_images && config.old_images=="Y") 
						{
							this.lesson.image = "";
							if(this.lesson.image_url != null && this.lesson.image_url != ""){
								this.apiName = "image";
								oAC.getAllRecord(oAC.getApiParam(this), {url:this.lesson.image_url}).then(
								imgRes => 
									{
										var temp = this.lesson;
										if(this.lesson){
											this.lesson.image = 'data:image/jpeg;base64, '+imgRes.image;
										}
										this.lesson = {};
										this.lesson = temp;
									}
								)
								.catch((err)=> {
									oltConsole.log(err);
								}); 
							}
							
								   
						} 
						else 
						{  
						} 
						
                    }else{
                        this.lesson = {};
                    }
                    if(res != null && res.lesson_mode != null && res.lesson_mode.length > 0){   
                        this.lesson_mode = res.lesson_mode;
                    }else{
                        this.lesson_mode = [];
                    }
                    if(res != null && res.history_user_product != null && res.history_user_product.length > 0){   
                        this.history_user_product = res.history_user_product;
                    }else{
                        this.history_user_product = [];
                    }
                    if(res != null && res.history_user_lesson_schedule != null && res.history_user_lesson_schedule.length > 0){   
                        this.history_user_lesson_schedule = res.history_user_lesson_schedule;
                        this.hasHistoryEssay = (res.history_user_lesson_schedule[0].essay != "" && res.history_user_lesson_schedule[0].essay == 'Y');
                        this.hasHistoryWordPreview = (res.history_user_lesson_schedule[0].word_preview != "" && res.history_user_lesson_schedule[0].word_preview == 'Y');
                        this.hasHistoryExplanation = (res.history_user_lesson_schedule[0].explanation != "" && res.history_user_lesson_schedule[0].explanation == 'Y');
                        this.hasHistoryWordReview = (res.history_user_lesson_schedule[0].word_review != "" && res.history_user_lesson_schedule[0].word_review == 'Y');
                        this.hasHistoryExam = (res.history_user_lesson_schedule[0].exam != "" && res.history_user_lesson_schedule[0].exam == 'Y');
                        this.correctRate = (res.history_user_lesson_schedule[0].correct_rate != null)? res.history_user_lesson_schedule[0].correct_rate: -1;
                    }else{
                        this.history_user_lesson_schedule = [];
                    }
                    if(res != null && res.history_user_lesson_schedule_count){   
                        this.history_user_lesson_schedule_count = res.history_user_lesson_schedule_count;
                    }else{
                        this.history_user_lesson_schedule_count = 0;
                    }
                    if(res != null && res.show_left_button && res.show_left_button != ""){ 
                        if(res.show_left_button == "Y") {
                            this.isDisabledLeft = false;
                        }else{
                            this.isDisabledLeft = true;
                        }
                    }else{
                        this.isDisabledLeft = true;
                    }
                    if(this.isQuiz && this.$GlobalData.uid != ""){
                        this.isQuiz = false;
                        data.quiz = "N";
                        this.$refs.taskListExam.onShow();
                    }



                    

                }
            )
            .catch((err)=> {
                oltConsole.log(err);
            });
            // 記錄點擊數
            if (this.$GlobalData.lastLsid != lsid) {
                this.$GlobalData.lastLsid = lsid;
                this.$JsBridge.callHandler('getVueNativeAppVersion', "", this.postHistoryEventLessonList);
            }
        },
        postHistoryEventLessonList(version){
            // 去五項任務返回時不記錄，切換不同課程要記錄點擊數
            if (version != undefined && version != "") {            
                this.apiName = 'history_event_lesson_list';
                this.act = "post";
                this.form["uid"] = this.$GlobalData.uid;
                this.form["lsid"] = this.$GlobalData.lastLsid;
                if(this.$JsBridge.isiOS()){
                    this.form["device"] = "ios";
                } else if (this.$JsBridge.isAndroid()){
                    this.form["device"] = "android";
                } else {
                    this.form["device"] = "pc";
                }
                this.form["model"] = "phone";
                this.form["version"] = version;
                oAC.postRecord(oAC.getApiParam(this), {}).then(res => {

                })
                .catch((err)=> {
                    oltConsole.log(err);
                });
            }
        },

        onStartup(query){
            if(this.$GlobalData.user && this.$GlobalData.user.rt){
                if(this.$GlobalData.user.rt.user && this.$GlobalData.user.rt.user.length > 0){
                    var ulid = this.$GlobalData.user.rt.user[0].ulid;
                    this.isMember = ulid == this.$GlobalData.user_level_level2;
                    this.isVisitor = ulid == "" || ulid == this.$GlobalData.user_level_level1;
                    this.isShowQuestStatus = ulid == this.$GlobalData.user_level_level3 || ulid == this.$GlobalData.user_level_level4;
                }
            }
            var data = {};
            if(this.$route.query && this.$route.query != null && this.$route.query != undefined){
                data = this.$route.query;
            }
            if(query && query != null && query != undefined){
                data = query;
            }
            if(data && data != null && data != undefined){
                if(data.backUrl && data.backUrl != "" && data.backUrl !== "undefined"){
                    this.backPath = data.backUrl;
                }else{
                    this.backPath = "";
                }
                if(data.quiz && data.quiz != "" && data.quiz !== "undefined"){
                    if(data.quiz == "Y"){
                        this.isQuiz = true;
                    }else{
                        this.isQuiz = false;
                    }
                }else{
                    this.isQuiz = false;
                }
                if(data.lsid && data.lsid != "" && data.lsid !== "undefined"){
                    this.lsid = data.lsid;
                    this.getTaskList(data.lsid);
                }else{  
                    this.lsid = "";
                    this.backPath = "";           
                    if(this.$route.params.lsid && this.$route.params.lsid != undefined){
                        this.lsid = this.$route.params.lsid;
                        this.getTaskList(this.$route.params.lsid);
                    }
                }
            }else{ 
                this.backPath = "";            
                if(this.$route.params.lsid && this.$route.params.lsid != undefined){
                    this.getTaskList(this.$route.params.lsid);
                }
            }       
            
            this.$JsBridge.callHandler('log', `lsid ==> ${this.lsid}`, this.changeToWebCallback);
        },

    },
    created(){ 
        if(this.$router.query != undefined && this.$router.query.uid){
            this.$GlobalData.uid = this.$router.query.uid;
        }

        if(this.$route.params.backUrl && this.$route.params.backUrl != "" && this.$route.params.backUrl != undefined){
            this.backUrl = this.$route.params.backUrl;
        }else if(this.$route.query.backUrl && this.$route.query.backUrl != "" && this.$route.query.backUrl != undefined){
            this.backUrl = this.$route.query.backUrl;
        }
        if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }
        this.onStartup(null);
    }
};
</script>