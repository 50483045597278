<template>
<div id="TaskListExam">  
    <!-- Modal -->
    <div class="modal " id="taskListExam" tabindex="-1" role="dialog" aria-labelledby="notice3Label" aria-hidden="true">
    <div class="modal-sm-big  modaldown  modal-dialog modal-dialog-down" role="document">
        <div class="modal-content rounded-bottom-0">
            <div class="modal-header">
            <h5 class="text-center text-lg fw-bold text-dark mb-0"> 隨堂測驗 </h5>
            </div>    
        <div class="modal-body text-center">

            <h5 class="text-s  text-gray mb-4"> 選擇想要測驗的方式 </h5>
            <div class="d-flex align-content-center justify-content-between my-3">
            <div class="w-50 text-center">
            <button type="button" :class="(isdisabledleft)?'btn btn-lg  btn-outline-info rounded-pill w-85 text-s mx-2 px-1 disabled':'btn btn-lg  btn-outline-info rounded-pill w-85 text-s mx-2 px-1'" @click="onFuncLeft">錯題重測</button>
            </div> 
            <div class="w-50 text-center">
            <button type="button" :class="(isdisabledright)?'btn btn-lg  btn-success rounded-pill w-85 text-s mx-2 px-1 disabled':'btn btn-lg  btn-success rounded-pill w-85 text-s mx-2 px-1' " @click="onFuncRight">從頭測驗</button> 
            </div>   
        </div>            
        </div>
        </div>
    </div>
    </div> 
</div>
</template>


<script>
export default {
    name: 'TaskListExam',   
        props: {
            onfuncleft: Function,
            onfuncright: Function,
            isdisabledleft: Boolean,
            isdisabledright: Boolean,
        }, 
    methods:{
        onShow(){
            $('#taskListExam').modal("show");
        },
        onHide(){
            $('#taskListExam').modal("hide");
        },
        onFuncLeft(){
            if(this.onfuncleft != null && this.onfuncleft != undefined){
                $(`#taskListExam`).on('hidden.bs.modal', () => {
                    $(`#taskListExam`).off('hidden.bs.modal');
                    this.onfuncleft();
                });
            }
            $('#taskListExam').modal("hide");
        },
        onFuncRight(){
            if(this.onfuncright != null && this.onfuncright != undefined){
                $(`#taskListExam`).on('hidden.bs.modal', () => {
                    $(`#taskListExam`).off('hidden.bs.modal');
                    this.onfuncright();
                });
            }
            $('#taskListExam').modal("hide");
        },
    },
    created(){  
    },
    mounted(){   
    },
    beforeDestroy(){
        //$('#taskListExam').remove();
    }
}
</script>
